.footer-container {
    background-color: #d7d7d7;
    color: black;
    padding: 10px;
    text-align: center;
    display: flex;
    flex-direction: column;
    width: 100%;
}
.links-container {
    display: flex;
    justify-content: center;
    gap: 5px;
}
.icon{
    height: 25px;
    width: auto;
}
.content{
    /*margin: 0 100px 0 100px;*/
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

}