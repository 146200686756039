.main-container {
    width: 100%;
    display: flex;
    flex-direction: column;

}
.row-container{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin: 0px 100px 0px 100px;
    align-items: center;
    justify-content: center;
}

.hero-section{
width: 100%;
height: 300px;
display: flex;
justify-content: center;
background: #b4c6f0;
position: relative;
}

.hero-image{
    height: 100%;
    width: auto;
}
.floating-text{
    position: absolute;
    text-align: center;
    top: 20%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
}
.Animation-Container {
    width: 100%;
    height: 300px;
}
.title{
    font-size: 30px;
    font-weight: bold;
    text-align: left;
    color: rgba(0, 0, 0, 0.87);
}
