
  /* Add some basic styles */
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  /* Style the search box container */
  .search-box {
    position: relative;
    width: 30px;
    height: 30px;
    margin-right: 0;
    border-radius: 25px;
    background: #eee;
    overflow: hidden;
    transition: width 0.3s ease-in-out;
  }

  /* Style the search input */
  .search-box input {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    border: none;
    outline: none;
    padding: 0 50px 0 20px;
    font-size: 18px;
    color: #333;
    background: none;
  }

  /* Style the search button */
  .search-box button {
    position: absolute;
    top: 0;
    right: 0;
    width: 30px;
    height: 30px;
    border: none;
    outline: none;
    cursor: pointer;
    background: #2196f3;
    color: #fff;
  }

  /* Expand the search box when clicked */
  .search-box.active {
    width: 250px;
  }

  .search-icon{
    display: flex;
    justify-content: center;
    align-items: center;
  }